// components/ShareAccountInfo.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountRequestDTO } from '../../types';
import axios from 'axios';

import './ShareAccountInfo.scss';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';

const ShareAccountInfo: React.FC = () => {
  
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [formData, setFormData] = useState<AccountRequestDTO>({} as AccountRequestDTO);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await axios.post<AccountRequestDTO>(`${process.env.REACT_APP_SERVICE_BASEPATH}/investments/`, formData);
      console.log('Account Request Created:', response.data);
      navigate('/request/' + response.data.token);
      // Optionally, clear form or provide user feedback
    } catch (error) {
      console.error('Error creating account request:', error);
      // Handle error
    } finally {
      setIsSubmitting(false); // Reset submission state regardless of outcome
    }
  };

  return (
    <div className='content-wrapper'>
    <header>
          <h1>Share Your Account Info</h1>
      </header>
      <div className='content-area'>
      <p>Getting started with your new account has never been easier. We’re here to guide you every step of the way as you connect your account and begin your journey with us.</p>

<h2>Quick and Secure Account Setup</h2>
<p>To ensure a smooth and secure setup, we’ll need a few details from you. This includes some basic information about yourself and your account number. Rest assured, your personal and financial information is protected with state-of-the-art security measures throughout the process.</p>

<h3>Here’s How It Works:</h3>
<ol>
    <li><strong>Fill Out the Form</strong>: Provide the requested details in the form below. It’s quick and easy!</li>
    <li><strong>Link Your Account with Plaid</strong>: On the next page, you’ll use Plaid to securely link your bank account. This streamlined process ensures your banking data is securely transmitted directly to us without any need for manual entry.</li>
    <li><strong>Complete Setup</strong>: Once submitted, we’ll take care of the rest, and you’ll be on your way to accessing all the features and benefits your account offers.</li>
</ol>

<h2>Ready to Begin?</h2>
<p>Your financial well-being is our top priority. Start by filling out the form below, and if at any point you need assistance, don’t hesitate to reach out. We’re here to help.</p>

    <div className='account-request-form'>
      <form onSubmit={handleSubmit}>
        <div className='form-item-wrapper'>
          <div className='form-item'>
            <label htmlFor="firstName">First Name</label>
            <input id="firstName" name="firstName" type="text" value={formData.firstName} onChange={handleChange} />
          </div>
          <div className='form-item'>
            <label htmlFor="lastName">Last Name</label>
            <input id="lastName" name="lastName" type="text" value={formData.lastName} onChange={handleChange} />
          </div>
          <div className='form-item'>
            <label htmlFor="accountNumber">CFR Account Number</label>
            <input id="accountNumber" name="accountNumber" type="text" value={formData.accountNumber} onChange={handleChange} />
          </div>
          <div className='form-item'>
            <label htmlFor="toEmail">Email</label>
            <input id="toEmail" name="toEmail" type="email" value={formData.toEmail} onChange={handleChange} />
          </div>
          <div className='form-item'>
            <label htmlFor="toPhone">Phone</label>
            <input id="toPhone" name="toPhone" type="tel" value={formData.toPhone} onChange={handleChange} />
          </div>
        </div>
        <button className='btn-arrow orange' type="submit" disabled={isSubmitting}>Create Account Request </button>
      </form>
      {isSubmitting && <LoadingIndicator />}
      </div>
    </div>
    </div>
  );
};

export default ShareAccountInfo;
