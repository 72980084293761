import React from 'react';
import './LoadingIndicator.scss'; // Import your stylesheet here

const LoadingIndicator = () => {
  return (
    <div className="spinner-container">
      <div className="spinner"></div>
    </div>
  );
};

export default LoadingIndicator;
