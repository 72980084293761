// components/RequestPage.tsx
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AccountRequestDTO } from '../../types';
import axios from 'axios';
import { PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';

const RequestPage: React.FC = () => {
  const { accountRequestToken } = useParams();
  const [accountRequest, setAccountRequest] = useState<AccountRequestDTO | null>(null); // Define a more specific type according to your data
  const [plaidToken, setPlaidToken] = useState<string>('');

  const navigate = useNavigate();

  useEffect(() => {

    const fetchDetails = async () => {

      const response = await axios.get(`${process.env.REACT_APP_SERVICE_BASEPATH}/investments/request/${accountRequestToken}`); // Adjust API endpoint as needed
      setAccountRequest(response.data);
    };

    if (accountRequestToken) fetchDetails();

  }, [accountRequestToken]);

  useEffect(() => {

    const fetchPlaidToken = async () => {
      const response = await axios({
        "method": "GET",
        "url": `${process.env.REACT_APP_SERVICE_BASEPATH}/investments/getToken`,
        "headers": {
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
        }
      })
        .then((response) => {
          let linkToken: string = response.data.linkToken;
          setPlaidToken(linkToken);
        })
        .catch((error) => {
          console.log(error)
        })
    };
    //only start link process if access token is not present
    if (accountRequest && !accountRequest.accessTokenPresent) fetchPlaidToken();

  }, [accountRequest]);


  const onSuccess = useCallback((token: any, metadata: any) => {
    // send token to server
    console.log(token);
    console.log(metadata);

    axios({
      "method": "POST",
      "url": `${process.env.REACT_APP_SERVICE_BASEPATH}/investments/request/${accountRequestToken}/saveAuth`,
      "headers": {
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
      },
      "data": {
        item: metadata.account_id,
        publicToken: token
      }
    })
      .then((response) => {
        setAccountRequest(response.data);
      })
      .catch((error) => {
        console.log(error)
      })

  }, []);

  const onExit = useCallback(() => {

    console.log("closed");
  }, []);




  const config: PlaidLinkOptions = {
    token: plaidToken,
    onSuccess: onSuccess,
    onExit: onExit,
    // ...
  };


  const { open, ready, error } = usePlaidLink(config);



  return (
    <div className='content-wrapper'>
      {accountRequest && !accountRequest.accessTokenPresent &&
        <Fragment>
          <header>
          <h1>Connect Your Account Securely with Plaid</h1>
      </header>
        <div className='content-area'>
          <section>
            <p>Ready to streamline your experience with CFR? Click the <strong>"Connect Account Using Plaid"</strong> button below to securely link your bank account. Our partnership with Plaid, a leader in financial security technology, ensures that your account details are transmitted securely and directly to CFR without ever compromising your data.</p>
            <p>This quick and secure process not only protects your information with top-tier encryption but also simplifies your journey with us. By connecting your account, you're taking the next step towards a more integrated and efficient financial experience.</p>
            <button className='btn-arrow orange btn-bottom' onClick={() => open()}>Connect Account Using Plaid</button>
          </section>
        </div>
        </Fragment>
      }
      {accountRequest && accountRequest.accessTokenPresent && plaidToken &&
      <Fragment>
        <header>
        <h1>Account Linked Successfully</h1>
      </header>
        <div className='content-area'>
          <section>
            
            <p>Congratulations! Your account has been securely linked with CFR through Plaid. You've taken an important step towards making your financial management smoother and more integrated.</p>
            <p>We're thrilled to have you with us on this journey. Rest assured, your financial data is protected with industry-leading security measures every step of the way.</p>
            <p>If you have any questions or need further assistance, please don't hesitate to <a href="#">contact our support team</a>.</p>
            <button className='btn-arrow orange btn-bottom' onClick={() => navigate('/')}>Return Home</button>
          </section>
        </div>
        </Fragment>
      }
      {accountRequest && accountRequest.accessTokenPresent && !plaidToken &&
      <Fragment>
        <header>
        <h1>Your Account is Already Linked</h1>
      </header>
        <div className='content-area'>
          <section>
            
            <p>Looks like you're all set! Your account is already securely linked to CFR through Plaid. There are no additional actions needed at this time.</p>
            <p>We're here to make your financial management effortless and secure. If you have any questions or if there's anything else you'd like to explore, our team is always ready to assist.</p>
            <p>For any inquiries or to make further updates to your account, feel free to <a href="#">contact our support team</a></p>
            <button className='btn-arrow orange btn-bottom' onClick={() => navigate('/')}>Return Home</button>
          </section>
        </div>
        </Fragment>
      }
    </div>
  );
};

export default RequestPage;
