// components/HomePage.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';

const HomePage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className='content-wrapper'>
      <header>
        <h1>Welcome to CFR's Secure Account Connect</h1>
      </header>
    <div className='content-area'>
    <section>
        <p>At Christian Financial Resources, we're committed to making your financial management experience seamless and secure. We're pleased to offer you a straightforward way to connect your banking information with us, powered by the trusted technology of <strong>Plaid.com</strong>.</p>
    </section>

    <section>
        <h2>Secure & Simple</h2>
        <p>Your security is our top priority. That's why we've partnered with Plaid, a leader in financial data security. Plaid's technology ensures that your banking information is encrypted end-to-end, keeping your details safe and your mind at ease.</p>
    </section>

    <section>
        <h2>How It Works</h2>
        <p>Connecting your banking information with CFR is simple:</p>
        <ol>
            <li><strong>Received Instructions?</strong> If you've received a text or email from us with specific instructions, please follow the provided steps to securely connect your banking information.</li>
            <li><strong>Self-Service Submission:</strong> Prefer to submit your information yourself? No problem! You can securely provide your banking details by verifying your CFR account number. Simply click on the "Share Your Account Info" button below to get started.</li>
        </ol>
    </section>

    <section>
        <h2>Share Your Account Info</h2>
        <button className='btn-arrow orange' onClick={() => navigate('/share-account-info')}>Connect Account Info</button>
        <p>This service allows us to streamline processes like direct deposits, withdrawals, and more, ensuring that your financial operations are as smooth as possible.</p>
    </section>

    <footer>
        <h2>Questions or Concerns?</h2>
        <p>Your peace of mind is important to us. If you have any questions about connecting your banking information or if you encounter any issues along the way, our dedicated support team is here to help. <a href="#">Contact Us</a></p>
        <p>Thank you for choosing CFR. We're excited to continue providing you with exceptional financial services.</p>
    </footer>
    </div>
    </div>
  );
};

export default HomePage;
