// App.tsx
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import ShareAccountInfo from './components/ShareAccountInfo';
import RequestPage from './components/RequestPage';
import './App.scss';

function App() {
  return (
    <div className='account-connect'>
      <header className='account-connect-header'>
        <img className='logo' src='/cfr-logo-tagline-white.png' />
      </header>
      <div className='account-connect-body'>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/share-account-info" element={<ShareAccountInfo />} />
        <Route path="/request/:accountRequestToken" element={<RequestPage />} />
      </Routes>
    </BrowserRouter>
    </div>
    <div className='footer-wrapper'>
      <footer>
      Please note that this is neither an offer to sell nor a solicitation of an offer to buy Christian Financial Resources securities. Such an offer is made by an Offering Circular and only in those states where Christian Financial Resources securities may lawfully be offered or sold. Christian Financial Resources securities are subject to certain risk factors as described in the Offering Circular and are not F.D.I.C. or S.I.P.C. insured nor are they bank deposits. NOTE: CFR has the right to call Certificates for redemption at any time upon sixty (60) days written notice. In such event, interest will be paid on the date of redemption. © 2021 Christian Financial Resources, Inc. <a href="https://www.cfrministry.org/privacy/">Privacy Policy</a>
      </footer>
    </div>
    </div>
  );
}

export default App;
